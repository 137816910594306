import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'gatsby'
import LeFrisé from '../../images/le-frise.png'
import styled from 'styled-components'
import FloatingBarArrow from '../../images/floating-bar-arrow.png'

const BottomPopupWrapper = styled.div`
    background-color: white;
    border-top: #fac400 4px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: ${(props) => (props.show ? 0 : '-300px')};
    left: 0;
    width: 100%;
    height: 80px;
    text-align: center;
    transition: bottom 2s;
    > div {
        max-width: 1160px;
        margin: 0 auto;
    }
    img {
        position: absolute;
        bottom: -100px;
        width: 160px;
    }
    a {
        background-color: #fac400;
        border-radius: 4px;
        color: black;
        font-weight: 600;
        padding: 10px 45px;
        text-transform: uppercase;
        &:visited {
            color: black;
        }
    }
    .fleche {
        filter: invert(1);
        position: absolute;
        top: -10px;
        width: 100px;
    }
    @media (max-width: 667px) {
        display: none;
    }
`

const BottomPopup = () => {
    const [currentY, setCurrentY] = useState(0)
    const [showPopup, setShowPopup] = useState(false)
    function checkScroll() {
        if (window.pageYOffset > 350 && showPopup === false) {
            setShowPopup(true)
        }
        if (window.pageYOffset <= 350 && showPopup === true) {
            setShowPopup(false)
        }
        setCurrentY(window.pageYOffset)
    }
    useEffect(() => {
        if (!window) {
            return
        }
        window.addEventListener('scroll', checkScroll)
        return () => {
            window.removeEventListener('scroll', checkScroll)
        }
    })
    return (
        <BottomPopupWrapper show={showPopup}>
            <Grid container>
                <Grid item xs={4}>
                    <img
                        src={LeFrisé}
                        alt="Un avocat frisé, il semble vraiment sympatique!"
                    />
                </Grid>
                <Grid item xs={4}>
                    <strong>Trouvez rapidement un avocat compétent!</strong>
                </Grid>
                <Grid item xs={4}>
                    <Link to="/comparer-avocats-rapidement">Cliquez ici</Link>
                    <img
                        src={FloatingBarArrow}
                        alt="Une flèche qui pointe vers le bouton"
                        className="fleche"
                    />
                </Grid>
            </Grid>
        </BottomPopupWrapper>
    )
}

export default BottomPopup
